import React, { useContext, useState, useMemo, useEffect } from 'react';
import { PlaytreeDataContext } from '../DataContexts/PlaytreeDataContext';
import { PlaylistDataContext } from '../DataContexts/PlaylistDataContext';
import CoverArtEditor from '../components/CoverArtEditor'
import './PlaytreeManager.css';

const PlaytreeManager = () => {
  const {
    playtrees,
    createPlaytree,
    addPlaylistToPlaytree,
    updatePlaytreeName,
    removePlaylistFromPlaytree,
  } = useContext(PlaytreeDataContext);
  const { playlists } = useContext(PlaylistDataContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [newPlaytreeName, setNewPlaytreeName] = useState('');
  const [selectedPlaytree, setSelectedPlaytree] = useState(null);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [playlistSearchQuery, setPlaylistSearchQuery] = useState('');
  const [playlistsMarkedForRemoval, setPlaylistsMarkedForRemoval] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editName, setEditName] = useState('');

  useEffect(() => {
    if (selectedPlaytree) {
      const updatedPlaytree = playtrees.find((pt) => pt.id === selectedPlaytree.id);
      if (updatedPlaytree) {
        setSelectedPlaytree(updatedPlaytree);
      }
    }
  }, [playtrees, selectedPlaytree]);

  const filteredPlaytrees = useMemo(() => {
    if (!playtrees) return [];
    return playtrees.filter((playtree) =>
      playtree.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [playtrees, searchQuery]);

  const sublists = useMemo(() => {
    if (!selectedPlaytree || !playlists) return [];
    return playlists.filter((playlist) =>
      selectedPlaytree.sublists.includes(playlist.id)
    );
  }, [selectedPlaytree, playlists]);

  const filteredPlaylists = useMemo(() => {
    if (!playlists || !selectedPlaytree) return [];
    return playlists.filter(
      (playlist) =>
        !selectedPlaytree.sublists.includes(playlist.id) &&
        playlist.name.toLowerCase().includes(playlistSearchQuery.toLowerCase())
    );
  }, [playlists, selectedPlaytree, playlistSearchQuery]);

  const handleAddToIntermediate = (playlist) => {
    if (!selectedPlaylists.find((selected) => selected.id === playlist.id)) {
      setSelectedPlaylists((prev) => [...prev, playlist]);
    }
  };

  const handleAddAllToIntermediate = () => {
    const newPlaylists = filteredPlaylists.filter(
      (playlist) => !selectedPlaylists.some((selected) => selected.id === playlist.id)
    );
    setSelectedPlaylists((prev) => [...prev, ...newPlaylists]);
  };

  const handleRemoveFromIntermediate = (playlistId) => {
    setSelectedPlaylists((prev) =>
      prev.filter((playlist) => playlist.id !== playlistId)
    );
  };

  const handleRemovePlaylist = (playlistId) => {
    if (playlistsMarkedForRemoval.includes(playlistId)) {
      setPlaylistsMarkedForRemoval((prev) =>
        prev.filter((id) => id !== playlistId)
      );
    } else {
      setPlaylistsMarkedForRemoval((prev) => [...prev, playlistId]);
    }
  };

  const handleAddToPlaytree = async () => {
    if (selectedPlaytree && selectedPlaylists.length > 0) {
      try {
        for (const playlist of selectedPlaylists) {
          await addPlaylistToPlaytree(selectedPlaytree.id, playlist.id);
        }
        setSelectedPlaylists([]);
      } catch (error) {
        console.error('Failed to add playlists to playtree:', error);
      }
    }
  };

  const handleSaveName = async () => {
    if (editName.trim() && selectedPlaytree) {
      try {
        await updatePlaytreeName(selectedPlaytree.id, editName.trim());
        if (playlistsMarkedForRemoval.length > 0) {
          for (const playlistId of playlistsMarkedForRemoval) {
            await removePlaylistFromPlaytree(selectedPlaytree.id, playlistId);
          }
          setPlaylistsMarkedForRemoval([]);
        }
        setIsEditingName(false);
      } catch (error) {
        console.error('Failed to update playtree name:', error);
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditingName(false);
    setEditName('');
    setPlaylistsMarkedForRemoval([]);
  };

  const handleCreatePlaytree = async () => {
    if (!newPlaytreeName.trim()) {
      alert('Please enter a name for the playtree.');
      return;
    }

    const newPlaytree = { name: newPlaytreeName.trim(), sublists: [] };
    try {
      await createPlaytree(newPlaytree);
      setNewPlaytreeName('');
    } catch (error) {
      console.error('Failed to create playtree:', error);
    }
  };

  return (
    <div className="playtree-manager">
      {/* Filter bar and Create Playtree */}
      <div className="filter-container">
        <input
          type="text"
          placeholder="Search playtrees..."
          className="search-box"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div className="create-playtree-container">
          <span className="create-playlist-label">Create new category:</span>
          <label>
            Name:
            <input
              type="text"
              className="create-playtree-name"
              value={newPlaytreeName}
              onChange={(e) => setNewPlaytreeName(e.target.value)}
            />
          </label>
          <button className="create-playtree-button" onClick={handleCreatePlaytree}>
            Create Playtree
          </button>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ul className="playtree-list-content">
            {filteredPlaytrees.map((playtree) => (
              <li
                key={playtree.id}
                className={selectedPlaytree?.id === playtree.id ? 'selected' : ''}
                onClick={() => setSelectedPlaytree(playtree)}
              >
                <span className="playtree-name">{playtree.name}</span>
                <span className="playtree-records">
                  {playtree.sublists?.length || 0} playlists
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="column">
          {selectedPlaytree ? (
            <div className="playtree-details">
              <div className="playtree-header">
                <div>
                  <CoverArtEditor resourceId={selectedPlaytree.id} extension="png" />
                </div>
                <div className="playtree-info">
                  {isEditingName ? (
                    <>
                      <input
                        type="text"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        className="edit-input"
                      />
                      <div className="edit-buttons">
                        <button className="edit-button" onClick={handleSaveName}>
                          Save
                        </button>
                        <button className="cancel-button" onClick={handleCancelEdit}>
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <h4>{selectedPlaytree.name}</h4>
                      <button
                        className="edit-button"
                        onClick={() => {
                          setIsEditingName(true);
                          setEditName(selectedPlaytree.name);
                        }}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              </div>
              <h5>Number of playlists: {sublists.length} playlists</h5>
              <table className="playlist-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Number of Songs</th>
                    {isEditingName && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {sublists.map((playlist) => (
                    <tr
                      key={playlist.id}
                      style={{
                        backgroundColor: playlistsMarkedForRemoval.includes(playlist.id)
                          ? '#ffcccc'
                          : 'transparent',
                      }}
                    >
                      <td>{playlist.name}</td>
                      <td>{playlist.playlistType}</td>
                      <td>{playlist.tracks.length}</td>
                      {isEditingName && (
                        <td>
                          <button
                            className={
                              playlistsMarkedForRemoval.includes(playlist.id)
                                ? 'add-button'
                                : 'remove-button'
                            }
                            onClick={() => handleRemovePlaylist(playlist.id)}
                          >
                            {playlistsMarkedForRemoval.includes(playlist.id) ? '+' : '-'}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Select a playtree to view details.</p>
          )}
        </div>

        <div className="column">
          {selectedPlaytree ? (
            <>
              {/* Intermediate Table */}
              {selectedPlaylists.length > 0 && (
                <div className="intermediate-table">
                  <h5>Selected Playlists</h5>
                  <table className="playlist-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPlaylists.map((playlist) => (
                        <tr key={playlist.id}>
                          <td>{playlist.name}</td>
                          <td>{playlist.playlistType}</td>
                          <td>
                            <button
                              className="remove-button"
                              onClick={() => handleRemoveFromIntermediate(playlist.id)}
                            >
                              X
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="intermediate-actions">
                    <button className="edit-button" onClick={handleAddToPlaytree}>
                      Add to Current Playtree
                    </button>
                    <button
                      className="cancel-button"
                      onClick={() => setSelectedPlaylists([])} // Clear all playlists
                    >
                      Delete All
                    </button>
                  </div>
                </div>
              )}

              <input
                type="text"
                placeholder="Search playlists..."
                className="search-box"
                value={playlistSearchQuery}
                onChange={(e) => setPlaylistSearchQuery(e.target.value)}
              />
              <table className="playlist-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>
                      <button
                        className="add-button"
                        onClick={handleAddAllToIntermediate}
                      >
                        Add All
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPlaylists.map((playlist) => (
                    <tr key={playlist.id}>
                      <td>{playlist.name}</td>
                      <td>{playlist.playlistType}</td>
                      <td>
                        <button
                          className="add-button"
                          onClick={() => handleAddToIntermediate(playlist)}
                        >
                          +
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>Select a playtree to add playlists.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaytreeManager;
