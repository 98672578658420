import React, { useState, useContext } from 'react';
import { DataContext } from '../DataContexts/DataContext';
import { FaPencilAlt, FaCheck, FaTimes } from 'react-icons/fa';
import CoverArtEditor  from '../components/CoverArtEditor'
import './ArtistEdit.css';

const ArtistEdit = ({ artist, onAlbumSelect, onSongSelect, selectedSong, selectedAlbum }) => {
  const { updateArtist } = useContext(DataContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(artist?.name || '');
  const [songSearchQuery, setSongSearchQuery] = useState('');


  if (!artist) {
    return <p>Please select an artist to view details.</p>;
  }


  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(artist.name);
  };

  const handleSaveArtistNameClick = async () => {
    if (editedName !== artist.name) {
      // Create an updated artist object with the new name
      const updatedArtist = {
        ...artist,
        name: editedName,
      };

      // Call updateArtist with the full artist object
      await updateArtist(updatedArtist);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedName(artist.name); // Revert to the original name if editing is canceled
  };

  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleAlbumClick = (album) => {
    onSongSelect(null); // Deselect song if an album is selected
    onAlbumSelect(album);
  };

  const handleSongClick = (track) => {
    onAlbumSelect(null); // Deselect album if a song is selected
    onSongSelect(track);
  };
  const totalTracks = artist.albums.reduce((count, album) => count + album.tracks.length, 0);
  const normalizedSearchQuery = songSearchQuery
    .normalize('NFD') // Decompose characters like é into e + diacritic
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritic marks
    .toLowerCase();

  // Filter albums based on the search query
  const filteredAlbums = artist.albums.filter((album) => {
    const normalizedAlbumName = album.name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    return (
      normalizedAlbumName.includes(normalizedSearchQuery) ||
      album.tracks.some((track) => {
        const normalizedTrackTitle = track.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        return normalizedTrackTitle.includes(normalizedSearchQuery);
      })
    );
  });

  // Filter songs based on the search query (from filtered albums)
  const filteredSongs = filteredAlbums.flatMap((album) =>
    album.tracks.filter((track) => {
      const normalizedTrackTitle = track.title
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      return normalizedTrackTitle.includes(normalizedSearchQuery);
    })
  );



  return (
    <div className="artist-edit">
      <div className="artist-header">
        <CoverArtEditor
          resourceId={artist.id}
        />

        <div className="artist-details">
          {isEditing ? (
            <div className="edit-mode">
              <input
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                className="name-input"
              />
              <FaCheck onClick={handleSaveArtistNameClick} className="icon save-icon" />
              <FaTimes onClick={handleCancelClick} className="icon cancel-icon" />
            </div>
          ) : (
            <div className="display-mode">
              <h3 className="artist-name">{artist.name}</h3>
              <FaPencilAlt onClick={handleEditClick} className="icon edit-icon" />
            </div>
          )}
          <p className="artist-songs-count">{totalTracks} songs</p> {/* Add song count */}
        </div>
      </div>
      <input
        type="text"
        placeholder="Search albums and songs..."
        value={songSearchQuery}
        onChange={(e) => setSongSearchQuery(e.target.value)}
        className="search-songs-input"
      />
      <div className="albums-list-container">
        <h4>Albums</h4>
        <ul className="albums-list" style={{ maxHeight: '220px', overflowY: 'auto' }}>
          {filteredAlbums.map((album) => (
            <li
              key={album.id}
              onClick={() => handleAlbumClick(album)}
              className={`list-item ${selectedAlbum?.id === album.id ? 'selected' : ''}`}
            >
              <span className="album-name">{album.name}</span>
              <span className="album-tracks">{album.tracks.length} tracks</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="songs-list-container">
        <h4>Songs</h4>
        <ul className="songs-list" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {filteredSongs.map((track) => (
            <li
              key={track.id}
              onClick={() => handleSongClick(track)}
              className={`list-item ${selectedSong?.id === track.id ? 'selected' : ''}`}
            >
              <span className="song-title">{track.title}</span>
              <span className="song-duration">{formatDuration(track.durationMs)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ArtistEdit;
