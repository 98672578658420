import React, { useState } from 'react';
import './PlaylistHeader.css';
import PlaylistManager from './PlaylistManager';
import PlaytreeManager from '../PlaytreeManager/PlaytreeManager';

const PlaylistHeader = () => {
  const [activeTab, setActiveTab] = useState('playlist');

  return (
    <div class="playlist-navigator-header">
      <div class="tab-container">
        <button
          class={`tab ${activeTab === 'playlist' ? 'active' : ''}`}
          onClick={() => setActiveTab('playlist')}
        >
          Playlists
        </button>
        <button
          class={`tab ${activeTab === 'playtree' ? 'active' : ''}`}
          onClick={() => setActiveTab('playtree')}
        >
          Categorieën
        </button>
      </div>
      <div class="tab-content">
        {activeTab === 'playlist' && (
          <div>
            <PlaylistManager></PlaylistManager>
          </div>
        )}
        {activeTab === 'playtree' && (
          <div>
            <PlaytreeManager></PlaytreeManager>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaylistHeader;
