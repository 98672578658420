import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Notification from '../components/Notification';

export const PlaytreeDataContext = createContext({
  playtrees: [],
  playtreesLoading: false,
  playtreesError: null,
  fetchPlaytrees: async () => {},
  createPlaytree: async () => {},
  updatePlaytreeName: async () => {}, // Updated method name
  removePlaylistFromPlaytree: async () => {}, // New method
  addPlaylistToPlaytree: async () => {}, // New method
});

const PlaytreeDataProvider = ({ children }) => {
  const { authData } = useContext(AuthContext);
  const [playtrees, setPlaytrees] = useState([]);
  const [playtreesLoading, setPlaytreesLoading] = useState(false);
  const [playtreesError, setPlaytreesError] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const showSuccessNotification = (message) => {
    addNotification(message, 'success');
  };

  const showErrorNotification = (message, requestBody) => {
    addNotification(`${message}. Request: ${JSON.stringify(requestBody)}`, 'error');
  };

  const removeNotification = (id) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  };

  // Fetch playtrees
  const fetchPlaytrees = async () => {
    if (authData?.accessToken) {
      setPlaytreesLoading(true);
      setPlaytreesError(null);
      try {
        const response = await fetch('https://backend.digitalejukebox.be/playtrees', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch playtrees');
        }

        const data = await response.json();
        setPlaytrees(data);
      } catch (error) {
        setPlaytreesError(error.message);
      } finally {
        setPlaytreesLoading(false);
      }
    }
  };

  // Create a new playtree
  const createPlaytree = async (playtreeData) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch('https://backend.digitalejukebox.be/playtrees', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(playtreeData),
        });

        if (!response.ok) {
          throw new Error('Failed to create playtree');
        }

        const newPlaytree = await response.json();
        setPlaytrees((prev) => [...prev, newPlaytree]);
        showSuccessNotification(`Playtree created: ${newPlaytree.name}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(playtreeData));
      }
    }
  };

  // Update playtree name
  const updatePlaytreeName = async (playtreeId, newName) => {
    if (authData?.accessToken) {
      const requestBody = { name: newName }; // Only send the name key

      try {
        const response = await fetch(
          `https://backend.digitalejukebox.be/playtrees/${playtreeId}`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${authData.accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to update playtree name');
        }

        // Update the playtree in the local cache
        setPlaytrees((prevPlaytrees) =>
          prevPlaytrees.map((playtree) =>
            playtree.id === playtreeId ? { ...playtree, name: newName } : playtree
          )
        );

        showSuccessNotification(`Playtree name updated to: ${newName}`);
      } catch (error) {
        showErrorNotification(error.message, requestBody);
      }
    }
  };

  // Remove a playlist from a playtree
  const removePlaylistFromPlaytree = async (playtreeId, playlistId) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(
          `https://backend.digitalejukebox.be/playtrees/${playtreeId}/sublist/${playlistId}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${authData.accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to remove playlist from playtree');
        }

        // Update the playtree's sublists locally
        setPlaytrees((prevPlaytrees) =>
          prevPlaytrees.map((playtree) =>
            playtree.id === playtreeId
              ? {
                  ...playtree,
                  sublists: playtree.sublists.filter((id) => id !== playlistId),
                }
              : playtree
          )
        );

        showSuccessNotification(`Playlist removed from playtree.`);
      } catch (error) {
        showErrorNotification(error.message, { playtreeId, playlistId });
      }
    }
  };

  // Add a playlist to a playtree
  const addPlaylistToPlaytree = async (playtreeId, playlistId) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(
          `https://backend.digitalejukebox.be/playtrees/${playtreeId}/sublist/${playlistId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${authData.accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to add playlist to playtree');
        }

        // Update the playtree's sublists locally
        setPlaytrees((prevPlaytrees) =>
          prevPlaytrees.map((playtree) =>
            playtree.id === playtreeId
              ? {
                  ...playtree,
                  sublists: [...playtree.sublists, playlistId],
                }
              : playtree
          )
        );

        showSuccessNotification(`Playlist added to playtree.`);
      } catch (error) {
        showErrorNotification(error.message, { playtreeId, playlistId });
      }
    }
  };

  useEffect(() => {
    fetchPlaytrees();
  }, [authData?.accessToken]);

  return (
    <PlaytreeDataContext.Provider
      value={{
        playtrees,
        playtreesLoading,
        playtreesError,
        fetchPlaytrees,
        createPlaytree,
        updatePlaytreeName, // Updated method
        removePlaylistFromPlaytree, // New method
        addPlaylistToPlaytree, // New method
      }}
    >
      {children}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() => removeNotification(notification.id)}
          />
        ))}
      </div>
    </PlaytreeDataContext.Provider>
  );
};

export default PlaytreeDataProvider;
