import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Notification from '../components/Notification';

export const PlaylistDataContext = createContext({
  playlists: [],
  playlistsLoading: false,
  playlistsError: null,
  fetchPlaylists: async () => {},
  createPlaylist: async () => {},
  updatePlaylist: async () => {},
  deletePlaylist: async () => {},
});

const PlaylistDataProvider = ({ children }) => {
  const { authData } = useContext(AuthContext);
  const [playlists, setPlaylists] = useState([]);
  const [playlistsLoading, setPlaylistsLoading] = useState(false);
  const [playlistsError, setPlaylistsError] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const showSuccessNotification = (message) => {
    addNotification(message, 'success');
  };

  const showErrorNotification = (message, requestBody) => {
    addNotification(`${message}. Request: ${JSON.stringify(requestBody)}`, 'error');
  };

  const removeNotification = (id) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  };

  // Fetch playlists
  const fetchPlaylists = async () => {
    if (authData?.accessToken) {
      setPlaylistsLoading(true);
      setPlaylistsError(null);
      try {
        const response = await fetch('https://backend.digitalejukebox.be/playlists', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch playlists');
        }

        const data = await response.json();
        setPlaylists(data);
      } catch (error) {
        setPlaylistsError(error.message);
      } finally {
        setPlaylistsLoading(false);
      }
    }
  };

  // Create a new playlist
  const createPlaylist = async (playlistData) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch('https://backend.digitalejukebox.be/playlists', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(playlistData),
        });

        if (!response.ok) {
          throw new Error('Failed to create playlist');
        }

        const newPlaylist = await response.json();
        setPlaylists((prev) => [...prev, newPlaylist]);
        showSuccessNotification(`Playlist created: ${newPlaylist.name}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(playlistData));
      }
    }
  };

  // Update an existing playlist
  const updatePlaylist = async (updatedPlaylist) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(
          `https://backend.digitalejukebox.be/playlists/${updatedPlaylist.id}`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${authData.accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedPlaylist),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to update playlist');
        }

        const updated = await response.json();
        setPlaylists((prev) =>
          prev.map((playlist) => (playlist.id === updated.id ? updated : playlist))
        );

        showSuccessNotification(`Playlist updated: ${updated.name}`);
      } catch (error) {
        showErrorNotification(error.message, updatedPlaylist);
      }
    }
  };

  // Delete a playlist
  const deletePlaylist = async (playlistId) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(`https://backend.digitalejukebox.be/playlists/${playlistId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete playlist');
        }

        setPlaylists((prev) => prev.filter((playlist) => playlist.id !== playlistId));
        showSuccessNotification('Playlist deleted successfully');
      } catch (error) {
        showErrorNotification(error.message, { playlistId });
      }
    }
  };

  useEffect(() => {
    fetchPlaylists();
  }, [authData?.accessToken]);

  return (
    <PlaylistDataContext.Provider
      value={{
        playlists,
        playlistsLoading,
        playlistsError,
        fetchPlaylists,
        createPlaylist,
        updatePlaylist,
        deletePlaylist,
      }}
    >
      {children}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() => removeNotification(notification.id)}
          />
        ))}
      </div>
    </PlaylistDataContext.Provider>
  );
};

export default PlaylistDataProvider;
