import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Notification from './components/Notification';
import './Header.css';

const Header = () => {
  const { authData } = useContext(AuthContext);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);

    // Automatically remove notification after 5 seconds
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, 5000);
  };

  const handleForceIndex = async () => {
    try {
      const response = await fetch('https://backend.digitalejukebox.be/reindex', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authData.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to force reindex');
      }

      addNotification('Reindexing started successfully!', 'success');
    } catch (error) {
      console.error(error);
      addNotification('Failed to start reindexing. Please try again.', 'error');
    }
  };

  return (
    <header className="header">
      <nav className="nav">
        <ul className="nav-links">
          <li><Link to="/">Music Manager</Link></li>
          <li><Link to="/tagmanager">Tags</Link></li>
          <li><Link to="/playlistheader">Playlists</Link></li>
          <li><Link to="/machine-manager">Machines</Link></li>
          <li><Link to="/ads-manager">Ads</Link></li>
        </ul>
        {authData?.accessToken && (
          <div
            className="user-info"
            onMouseEnter={() => setIsMenuVisible(true)}
            onMouseLeave={() => setIsMenuVisible(false)}
          >
            <span>Welcome, {authData.userName}!</span>
            {isMenuVisible && (
              <div className="dropdown-menu">
                <button onClick={handleForceIndex}>Force Index</button>
              </div>
            )}
          </div>
        )}
      </nav>
      {/* Notification Container */}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() =>
              setNotifications((prev) =>
                prev.filter((n) => n.id !== notification.id)
              )
            }
          />
        ))}
      </div>
    </header>
  );
};

export default Header;
