import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const navigate = useNavigate();
  const TOKEN_EXPIRY_BUFFER = 1800 * 1000; // 30 minutes in milliseconds

  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);

      // Check if expirationTime exists; if not, log out and clear outdated data
      if (!parsedAuthData.expirationTime) {
        logout(); // Log out if expirationTime is missing
        return;
      }

      const expirationTime = parsedAuthData.expirationTime;
      const currentTime = Date.now();

      // If the token has expired or is close to expiring within 30 minutes, clear it
      if (expirationTime - currentTime <= TOKEN_EXPIRY_BUFFER) {
        logout(); // Log out if the token is close to expiration
      } else {
        setAuthData(parsedAuthData);
        scheduleTokenCheck(expirationTime);
      }
    }
    setAuthLoading(false);
  }, []);

  // Set up a timeout to check for token expiration
  const scheduleTokenCheck = (expirationTime) => {
    const timeUntilExpiry = expirationTime - Date.now() - TOKEN_EXPIRY_BUFFER;
    if (timeUntilExpiry > 0) {
      setTimeout(() => {
        logout(); // Log out when token is close to expiration
      }, timeUntilExpiry);
    }
  };

  const login = (data) => {
    const expirationTime = Date.now() + data.expiresIn * 1000; // Calculate expiration time in milliseconds
    const authDataWithExpiry = { ...data, expirationTime };
    setAuthData(authDataWithExpiry);
    localStorage.setItem('authData', JSON.stringify(authDataWithExpiry));
    scheduleTokenCheck(expirationTime);
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem('authData');
    navigate('/login'); // Redirect to login page
  };

  return (
    <AuthContext.Provider value={{ authData, authLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
