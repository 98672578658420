// src/components/Notification.js
import React, { useEffect, useState } from 'react';
import './Notification.css';

const Notification = ({ message, type, onClose }) => {
  const [isLongMessage, setIsLongMessage] = useState(false);

  useEffect(() => {
    if (type === 'success') {
      const timer = setTimeout(onClose, 3000); // Auto-close success notifications
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [type, onClose]);

  // Determine if message is long
  useEffect(() => {
    setIsLongMessage(message.length > 50);
  }, [message]);

  // Copy full message to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message).then(() => {
    });
  };

  return (
    <div className={`notification ${type}`}>
      {isLongMessage ? (
        <span>{message.substring(0, 50)}...</span>
      ) : (
        <span>{message}</span>
      )}
      {isLongMessage && (
        <button onClick={handleCopyToClipboard} className="copy-button">
          Copy Full Message
        </button>
      )}
      {type === 'error' && (
        <button onClick={onClose} className="close-button">
          ×
        </button>
      )}
    </div>
  );
};

export default Notification;
