import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext'; // Import AuthContext
import Notification from '../components/Notification';
import './CoverArtEditor.css';

const CoverArtEditor = ({ resourceId, onUploadComplete, extension = 'jpg' }) => {
  const { authData } = useContext(AuthContext); // Access authData
  const initialCoverArtUrl = `https://tdj-s3.s3.amazonaws.com/${resourceId}.${extension}`;
  const uploadUrl = `https://backend.digitalejukebox.be/api/storage/uploadFile/`;

  const [previewImage, setPreviewImage] = useState(initialCoverArtUrl);
  const [isUploading, setIsUploading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [cacheBuster, setCacheBuster] = useState(Date.now()); // Cache-busting parameter


  // Update the previewImage whenever resourceId changes
  useEffect(() => {
    setPreviewImage(`https://tdj-s3.s3.amazonaws.com/${resourceId}.${extension}?cb=${cacheBuster}`);
  }, [resourceId, extension, cacheBuster]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);

    // Automatically remove notification after 5 seconds
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, 5000);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Preview the image
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target.result); // Show a preview of the selected image
      };
      reader.readAsDataURL(file);

      // Start the upload process
      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append('file', file, `${resourceId}.${extension}`); // Rename file to `id.extension`

        const response = await fetch(uploadUrl, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`, // Attach authorization token
          },
          body: formData, // Send the file using FormData
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        const data = await response;
        setIsUploading(false);
        addNotification('Cover art uploaded successfully', 'success');
        // Update cache-buster to force reload of the new image

        // Add a small delay before updating the cache buster
        setTimeout(() => {
          setCacheBuster(Date.now()); // Update the cache buster after a delay
        }, 1000); // 1-second delay

        if (onUploadComplete) {
          onUploadComplete(data.url); // Pass the uploaded file URL back
        }
      } catch (error) {
        setIsUploading(false);
        addNotification('Failed to upload cover art', 'error');
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div className="cover-art-editor">
      <img src={previewImage} alt="Cover Art" className="cover-art" />
      <div className="edit-link-container">
        <label htmlFor={`cover-art-input-${resourceId}`} className="edit-link">
          {isUploading ? 'Uploading...' : 'Edit'}
        </label>
        <input
          type="file"
          id={`cover-art-input-${resourceId}`}
          accept={`image/${extension === 'jpg' ? 'jpeg' : extension}`}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          disabled={isUploading}
        />
      </div>

      {/* Render Notifications */}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() =>
              setNotifications((prev) =>
                prev.filter((n) => n.id !== notification.id)
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

export default CoverArtEditor;
